<template>
  <el-upload
    class="avatar-uploader"
    :action="uploadUrl"
    :data="uploadData"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
  </el-upload>
</template>

<script setup>
import { ref, defineEmits, watch } from "vue";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import signObj from "../utils/upload-sign";
import { getDomain } from "../utils/tools";

const emit = defineEmits(["UploadEvent"]);

const props = defineProps({
  field: {
    type: String,
    default: "",
  },
  imgPath: {
    type: String,
    default: "",
  },
});

const imageUrl = ref("");
const uploadUrl = ref("");
const uploadData = ref({});

watch(
  () => props.imgPath,
  (val) => {
    console.log("img", val);
    if (props.imgPath != "") {
      imageUrl.value = getDomain() + val;
    }
  }
);

if (props.imgPath != "") {
  imageUrl.value = getDomain() + props.imgPath;
}

uploadUrl.value = getDomain() + "api/index/upload.html";

const handleAvatarSuccess = (response, uploadFile) => {
  console.log("handleAvatarSuccess", response, uploadFile);

  imageUrl.value = getDomain() + response.data.file_url;

  emit("UploadEvent", { field: props.field, img: response.data.file_url });

  // imageUrl.value = URL.createObjectURL()
};

const beforeAvatarUpload = (rawFile) => {
  signObj.rootPath = "/Public/images/driver/img/";
  uploadData.value = signObj;

  // if (rawFile.type !== "image/jpeg") {
  //   ElMessage.error("Avatar picture must be JPG format!");
  //   return false;
  // } else if (rawFile.size / 1024 / 1024 > 2) {
  //   ElMessage.error("Avatar picture size can not exceed 2MB!");
  //   return false;
  // }
  return true;
};
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
