<template>
  <el-dialog v-model="dialogVisible" :title="dialogTitle" width="48%">
    <div class="gps-form">
      <el-form
        ref="ruleFormRef"
        :show-message="false"
        :model="ruleForm"
        :rules="rules"
        status-icon
        label-width="120px"
      >
        <div
          class="flex-column"
          v-for="it_title in formArray"
          :key="it_title.title"
        >
          <div class="title">{{ it_title.title }}</div>
          <el-row
            :gutter="10"
            v-for="(it, ir) in it_title.children"
            :key="'r' + ir"
          >
            <el-col :offset="0" :span="24"
              ><el-form-item
                :label="it.label"
                :prop="it.field"
                v-if="it.type != 'empty'"
              >
                <template #label v-if="it.chk_all && it.chk_all == 1"
                  >{{ it.label }}
                  <el-checkbox @change="change_all($event, it)">{{
                    $t("rules.checkbox_videoAll")
                  }}</el-checkbox>
                </template>

                <div class="flex-row flex-avg">
                  <div class="it">
                    <div class="flex-row" v-if="it.type == 'ipt_ipts'">
                      <el-input
                        :class="it.cls"
                        v-for="(ipit, ip_i) in ruleForm[it.field]"
                        :key="ip_i"
                        clearable
                        :placeholder="ruleForm[it.field][ip_i]"
                        v-model="ruleForm[it.field][ip_i]"
                      />
                    </div>
                    <el-tree-select
                      :class="it.cls"
                      v-if="it.type == 'tsel'"
                      v-model="ruleForm[it.field]"
                      :data="it.data"
                      filterable
                    />
                    <el-input
                      :class="it.cls"
                      v-if="it.type == 'ipt'"
                      clearable
                      v-model="ruleForm[it.field]"
                      :placeholder="it.label"
                    />
                    <el-input
                      v-if="it.type == 'pwd'"
                      clearable
                      type="password"
                      show-password
                      v-model="ruleForm[it.field]"
                      :placeholder="it.label"
                    />
                    <el-input
                      :class="it.cls"
                      :rows="2"
                      type="textarea"
                      v-if="it.type == 'textarea'"
                      clearable
                      v-model="ruleForm[it.field]"
                      :placeholder="it.label"
                    />
                    <el-date-picker
                      :class="it.cls"
                      clearable
                      v-if="it.type == 'dtr'"
                      v-model="ruleForm[it.field]"
                      type="datetimerange"
                      range-separator="To"
                      :start-placeholder="it.title_s"
                      :end-placeholder="it.title_e"
                      value-format="YYYY-MM-DD HH:mm:ss"
                    />
                    <el-date-picker
                      :class="it.cls"
                      clearable
                      v-if="it.type == 'date'"
                      v-model="ruleForm[it.field]"
                      type="date"
                      :placeholder="it.label"
                      value-format="YYYY-MM-DD"
                    />
                    <el-date-picker
                      :class="it.cls"
                      clearable
                      v-if="it.type == 'datetime'"
                      v-model="ruleForm[it.field]"
                      type="datetime"
                      :placeholder="it.label"
                      value-format="YYYY-MM-DD HH:mm:ss"
                    />
                    <el-radio-group
                      v-model="ruleForm[it.field]"
                      v-if="it.type == 'radio'"
                      :class="it.cls"
                    >
                      <el-radio
                        v-for="item in it.data"
                        :key="item.value"
                        :label="item.value"
                        >{{ item.name }}</el-radio
                      >
                    </el-radio-group>
                    <el-checkbox-group
                      v-model="ruleForm[it.field]"
                      v-if="it.type == 'checkbox'"
                      :class="it.cls"
                    >
                      <el-checkbox
                        v-for="item in it.data"
                        :key="item.value"
                        :label="item.value"
                        >{{ item.name }}</el-checkbox
                      >
                    </el-checkbox-group>
                    <el-radio-group
                      v-model="ruleForm[it.field]"
                      v-if="it.type == 'radio_img'"
                      :class="it.cls"
                    >
                      <el-radio
                        v-for="item in it.data"
                        :key="item.value"
                        :label="item.value"
                        ><el-image :src="item.path" fit="fill"></el-image>
                      </el-radio>
                    </el-radio-group>
                    <el-select
                      :class="it.cls"
                      clearable
                      v-if="it.type == 'sel'"
                      v-model="ruleForm[it.field]"
                      :placeholder="it.label"
                      @change="
                        it.change &&
                          it.change($event, it, callbackForm, ruleForm)
                      "
                    >
                      <el-option
                        v-for="item in it.data"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                    <el-select
                      :class="it.cls"
                      clearable
                      v-if="it.type == 'msel'"
                      multiple
                      v-model="ruleForm[it.field]"
                      :placeholder="it.label"
                    >
                      <el-option
                        v-for="item in it.data"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                    <el-switch
                      :class="it.cls"
                      v-if="it.type == 'switch'"
                      v-model="ruleForm[it.field]"
                      active-value="1"
                      inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    />
                    <el-time-picker
                      :class="it.cls"
                      v-if="it.type == 'time'"
                      v-model="ruleForm[it.field]"
                      is-range
                      range-separator="To"
                      :start-placeholder="it.title_s"
                      :end-placeholder="it.title_e"
                      value-format="HH:mm:ss"
                      format="HH:mm:ss"
                    />
                    <gps-upload
                      v-if="it.type == 'upload'"
                      :field="it.field"
                      :imgPath="ruleForm[it.field]"
                      @UploadEvent="UploadEvent"
                    ></gps-upload>
                  </div>
                  <div class="it">{{ it.unit }}</div>
                </div>
              </el-form-item></el-col
            >
          </el-row>
        </div>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("commKey.Cancel")
        }}</el-button>
        <el-button type="primary" @click="submitForm()">
          {{ $t("commKey.Confirm") }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { Watch } from "@element-plus/icons-vue";
import {
  ref,
  toRefs,
  defineExpose,
  reactive,
  getCurrentInstance,
  watch,
  defineEmits,
  toRaw,
} from "vue";
import { useI18n } from "vue-i18n";

import GpsUpload from "../../../components/GpsUpload.vue";
const { proxy } = getCurrentInstance();
// const { $api } = proxy.appContext.config.globalProperties;
const { t } = useI18n();
const props = defineProps({
  dialogTitle: {
    type: String,
    default: "",
  },
  formArray: {
    type: Array,
    default: () => {},
  },
  row: {
    type: Object,
    default: () => {},
  },
  ruleType: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["saveEvent", "formEvent"]);

const dialogVisible = ref(false);
//请求数据

//表单控制
const ruleFormRef = ref();

let ruleFormObj = {};
let rulesObj = {};

props.formArray.map((item, index) => {
  //赋值操作
  item.children.forEach((ik) => {
    switch (ik.type) {
      case "ipt_ipts":
        if (ik.field) ruleFormObj[ik.field] = ik.val.split(",");
        break;
      default:
        if (ik.field) ruleFormObj[ik.field] = ik.val;
        break;
    }
    //验证数据规则
    if (ik.required) {
      rulesObj[ik.field] = [
        {
          required: true,
          message: ik.label + t("commKey.BITIAN"),
          trigger: "blur",
        },
      ];
    }
  });
});

console.log(ruleFormObj);

let ruleForm = reactive(ruleFormObj);
const rules = reactive(rulesObj);
// let formParaArrayData = {};
// const formParaArray = reactive(formParaArrayData);

const change_all = (evt, item) => {
  // console.log(evt, item);
  if (evt) {
    item.val = item.data.map((it) => {
      return it.value;
    });
  } else {
    item.val = [];
  }
};

const submitForm = async () => {
  // if (!formEl) return;
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      let formData = {
        RuleType: props.ruleType,
        More: "",
        ID: ruleForm["ID"],
        StartTime: 0,
        EndTime: 0,
      };
      let ay_alarm = [];
      let ay_link = [];
      let chk;
      props.formArray.forEach((it_form) => {
        it_form.children.forEach((it) => {
          switch (it.field) {
            case "RuleName":
            case "CompanyID":
              formData[it.field] = ruleForm[it.field];
              break;
            case "beginTime":
              if (ruleForm[it.field].length == 2) {
                formData["StartTime"] = ruleForm[it.field][0];
                formData["EndTime"] = ruleForm[it.field][1];
              }
              break;
            case "beginTime1":
            case "beginTime2":
              if (it.cls == "ipt-alarm") {
                ay_alarm.push(ruleForm[it.field].join(","));
              }
              break;
            case "alarmTypes":
              if (it.cls == "ipt-alarm") {
                ay_alarm.push(ruleForm[it.field].join(","));
              }
              break;
            case "voice":
              formData["AlarmMsg"] = ruleForm[it.field];
              break;
            default:
              if (it.cls == "ipt-alarm") {
                ay_alarm.push(ruleForm[it.field]);
              } else if (it.cls == "ipt-link") {
                if (it.field == "cameraChannel" || it.field == "videoChannel") {
                  chk = [0, 0, 0, 0, 0, 0, 0, 0];
                  if (ruleForm[it.field] instanceof Array) {
                    ruleForm[it.field].forEach((it) => {
                      chk[it - 1] = 1;
                    });
                  }
                  ay_link.push(chk.join(""));
                } else {
                  ay_link.push(ruleForm[it.field]);
                }
              }
              break;
          }
        });
      });

      formData["AlarmParam"] = ay_alarm.join(",");
      formData["LinkParam"] = ay_link.join(",");

      console.log(formData, props.formArray);
      // console.log(ruleFormObj);toRaw
      // emit("saveEvent", ruleFormObj);
      // console.log(toRaw(ruleForm));
      emit("saveEvent", formData);
    } else {
      console.log("error submit!", fields);
    }
  });
};

const callbackForm = (obj) => {
  if (obj.toType) {
    let val = null;
    switch (obj.toType) {
      case "ipt_ipts":
        val = [];
        for (let i = 0; i < parseInt(obj.val); i++) {
          val.push("CH" + (i + 1));
        }
        break;
    }
    ruleForm[obj.toField] = val;
  } else {
    emit("formEvent", obj);
  }
};

const int2time = (num) => {
  let h = Math.floor(parseInt(num) / 3600);
  let m = Math.floor((num - h * 3600) / 60);
  let s = Math.floor(num - h * 3600 - m * 60);
  return (
    (h > 9 ? h : "0" + h) +
    ":" +
    (m > 9 ? m : "0" + m) +
    ":" +
    (s > 9 ? s : "0" + s)
  );
};

watch(dialogVisible, (val) => {
  if (!val) {
    // ruleFormRef.resetFields();
  }
});
watch(
  () => props.row,
  (val) => {
    Object.assign(ruleForm, val);
    console.log("val", val);
    if (val.ID > 0) {
      let ay_alarm_temp = val.rAlarmParam.split(",");
      let ay_alarm_idx = 0;
      let ay_link_temp = val.rLinkParam.split(",");
      let ay_link_idx = 0;
      props.formArray.forEach((it_form) => {
        it_form.children.forEach((it) => {
          switch (it.field) {
            case "RuleName":
              ruleForm[it.field] = val["rRuleName"];
              break;
            case "CompanyID":
              ruleForm[it.field] = val["rCompanyID"];
              break;
            case "beginTime":
              ruleForm[it.field] = [
                int2time(val["rStartTime"]),
                int2time(val["rEndTime"]),
              ];
              break;
            case "beginTime1":
              ruleForm[it.field] = [ay_alarm_temp[0], ay_alarm_temp[1]];
              ay_alarm_idx = ay_alarm_idx + 2;
              break;
            case "beginTime2":
              ruleForm[it.field] = [ay_alarm_temp[2], ay_alarm_temp[3]];
              ay_alarm_idx = ay_alarm_idx + 2;
              break;
            case "alarmTypes":
              ruleForm[it.field] = ay_alarm_temp;
              break;
            case "voice":
              ruleForm[it.field] = val["rAlarmMsg"];
              break;
            default:
              if (it.cls == "ipt-alarm") {
                ruleForm[it.field] = ay_alarm_temp[ay_alarm_idx++];
                // ay_alarm.push(ruleForm[it.field]);
              } else if (it.cls == "ipt-link") {
                if (it.field == "cameraChannel" || it.field == "videoChannel") {
                  let aa_ay = ay_link_temp[ay_link_idx].split("");

                  let ay_kk = [];
                  aa_ay.forEach((ix, ixx) => {
                    if (ix == 1) {
                      ay_kk.push((ixx + 1).toString());
                    }
                  });
                  console.log("aa_ay", ay_kk);
                  ruleForm[it.field] = ay_kk;
                  // chk = [0, 0, 0, 0, 0, 0, 0, 0];
                  // if (ruleForm[it.field] instanceof Array) {
                  //   ruleForm[it.field].forEach((it) => {
                  //     chk[it - 1] = 1;
                  //   });
                  // }
                  // ay_link.push(chk.join(""));
                } else {
                  ruleForm[it.field] = ay_link_temp[ay_link_idx];
                }
                ay_link_idx++;
              }
              break;
          }
        });
      });
    }
  }
);
// const { text } = toRefs(props);
// const formatText = `Hi,${text && text.value}`;

///////////////////
const propoption = {
  icon: "icon",
  value: "gps_id",
  label: "nodeName",
  children: "children",
};

////////////////////////////////////////////
const UploadEvent = (obj) => {
  ruleForm[obj.field] = obj.img;
};

defineExpose({ dialogVisible });
</script>

<style lang="scss" scoped>
::v-deep(.el-select) {
  width: 100%;
}
::v-deep(label) {
  margin-bottom: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
  .it {
    box-sizing: border-box;
    width: 100%;
    padding: 0 5px;
    &:last-child {
      width: 180px;
    }
  }
}
.flex-avg {
  flex: 1;
}
.flex-column {
  display: flex;
  flex-direction: column;
  .title {
    height: 30px;
    line-height: 30px;
    border-bottom: solid 1px #e3e3e3;
    margin: 10px 0;
  }
}
</style>
